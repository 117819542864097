document.addEventListener('turbolinks:load', () => {
  const message = document.querySelector('.flash-message')
  const fadeOutFlashMessage = () => {
    const timer_id = setInterval(() => {
      const opacity = message.style.opacity
      if (opacity > 0) {
        message.style.opacity = opacity - 0.02
      } else {
        message.style.display = 'none'
        clearInterval(timer_id)
      }
    }, 10) // Decrease this value to increase fadeout speed
  }

  if (message !== null) {
    message.style.opacity = 1
    setTimeout(fadeOutFlashMessage, 3000) // Time in ms that flash message is shown
  }
})
